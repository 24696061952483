















































































































































































































































































































































































































.searchboxfl {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start !important;
}
.sjd {
  width: 5.2rem;
  input {
    border: none;
    border-bottom: 1px solid #dcdfe6;
    border-radius: 0;
  }
}
